import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import axios from 'axios';

const ProjectData = () => {
  const [user, setUser] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [url, setUrl] = useState('');
  const [businessData, setBusinessData] = useState([]);
  const [gmbData, setGmbData] = useState([]); // Store GMB data here
  const [errorMessage, setErrorMessage] = useState('');
  
  const [showModal, setShowModal] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState([]);
  
  const db = firebase.firestore();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      setUser(authUser); 
    });

    return () => unsubscribe(); 
  }, []);

  useEffect(() => {
    if (!user) {
      setBusinessData([]); 
      return; 
    }

    const fetchBusinessData = async () => {
      try {
        const snapshot = await db.collection('users')
          .doc(user.uid)
          .collection('businessData')
          .get();

        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBusinessData(data);
      } catch (error) {
        console.error('Error fetching business data:', error);
        setErrorMessage('Error fetching business data.');
      }
    };

    fetchBusinessData();
  }, [user, db]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setErrorMessage('You must be logged in to add business data.');
      return;
    }

    if (!businessName || !address || !url) {
      setErrorMessage('All fields are required!');
      return;
    }

    const newBusiness = { businessName, address, url };

    try {
      const docRef = await db.collection('users')
        .doc(user.uid) 
        .collection('businessData')
        .add(newBusiness);

      setBusinessData([...businessData, { id: docRef.id, ...newBusiness }]);
      setBusinessName('');
      setAddress('');
      setUrl('');
      setErrorMessage('');
    } catch (error) {
      console.error('Error adding business data:', error);
      setErrorMessage('Error adding business data.');
    }
  };

  const handleFetchDataClick = () => {
    setShowModal(true);  
  };

  const handleModalClose = () => {
    setShowModal(false);  
    setSelectedBusiness([]);  
  };

  const handleModalOk = async () => {
    if (selectedBusiness.length === 0) {
      setErrorMessage('Please select at least one business.');
      return;
    }

    if (!user) {
      setErrorMessage('User is not authenticated.');
      return;
    }

    try {
      const idToken = await user.getIdToken(); // Get the Firebase ID Token

      const response = await axios.post(
        'https://www.onpageseopulse.com/api/fetch-gmb-data',
        {
          businesses: selectedBusiness, // Send the selected businesses
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // Send the ID Token as Authorization header
          },
        }
      );

      const gmbFetchedData = response.data.results;

      // Store the GMB data in Firestore
      const batch = db.batch();
      gmbFetchedData.forEach((data) => {
        if (!data.error) {
          const businessRef = db.collection('users')
            .doc(user.uid)
            .collection('businessData')
            .doc(data.business_name); // Using business name as doc id for example
          batch.set(businessRef, data);
        }
      });

      await batch.commit();

      setGmbData(gmbFetchedData);
      setShowModal(false);
      setSelectedBusiness([]);
    } catch (error) {
      console.error('Error fetching GMB data:', error);
      setErrorMessage('Error fetching Google My Business data.');
    }
  };

  const handleCheckboxChange = (e, businessId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedBusiness((prev) => [...prev, businessId]);
    } else {
      setSelectedBusiness((prev) => prev.filter(id => id !== businessId));
    }
  };

  return (
    <Container className="my-5">
      <Row className="text-center mb-4">
        <Col>
          <h2>Add Business Data</h2>
        </Col>
      </Row>

      {user && (
        <>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="businessName">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter business name"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="url">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>

          <Row className="mt-5">
            <Col>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Business Name</th>
                    <th>Address</th>
                    <th>URL</th>
                  </tr>
                </thead>
                <tbody>
                  {businessData.map((business, index) => (
                    <tr key={business.id}>
                      <td>{index + 1}</td>
                      <td>{business.businessName}</td>
                      <td>{business.address}</td>
                      <td>
                        <a href={business.url} target="_blank" rel="noopener noreferrer">
                          {business.url}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="text-center">
              <Button variant="info" onClick={handleFetchDataClick}>
                Fetch Data
              </Button>
            </Col>
          </Row>
        </>
      )}

      {errorMessage && (
        <Row className="mt-3">
          <Col className="text-center">
            <div className="alert alert-danger">
              {errorMessage}
            </div>
          </Col>
        </Row>
      )}

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Business Names</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {businessData.map((business) => (
              <Form.Check
                key={business.id}
                type="checkbox"
                label={business.businessName}
                value={business.id}
                onChange={(e) => handleCheckboxChange(e, business.id)}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalOk}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {gmbData.length > 0 && (
        <Row className="mt-5">
          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Business Name</th>
                  <th>Phone</th>
                  <th>Rating</th>
                  <th>Reviews</th>
                </tr>
              </thead>
              <tbody>
                {gmbData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.business_name}</td>
                    <td>{data.phone || 'N/A'}</td>
                    <td>{data.rating || 'N/A'}</td>
                    <td>{data.reviews_count || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ProjectData;
