import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import DetailedReportPage from "./components/DetailedReportPage";
import AutoModeStats from "./components/AutoModeStats";
import App from "./App";
import ProjectDetails from "./components/ProjectDetails";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import Localranking from "./components/Localranking";
import Projectdata from "./components/Projectdata"; 

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/app" element={<App />} />
        <Route path="/detailed-report/:projectId" element={<DetailedReportPage />} />
        <Route path="/project/:projectId" element={<ProjectDetails />} />
        <Route path="/auto-mode-stats" element={<AutoModeStats />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/localranking" element={<Localranking />} />
        <Route path="/projectdata" element={<Projectdata />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
