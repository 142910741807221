import React, { useState } from 'react';
import './Localranking.css';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Localranking = () => {
  const [keyword, setKeyword] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const fetchLocalResults = async (keyword, city, country) => {
    if (!user) {
      toast.error('User is not authenticated.');
      return [];
    }

    try {
      const idToken = await user.getIdToken();

      const response = await axios.post(
        'https://www.onpageseopulse.com/api/local-search',
        { keyword, city, country },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data.results;
    } catch (err) {
      console.error('Error fetching local results:', err.message);
      setError('Failed to fetch local search results.');
      toast.error('Failed to fetch local search results.');
      return [];
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!keyword || !city || !country) {
      setError('All fields are required!');
      toast.error('All fields are required!');
      return;
    }

    setError('');
    setLoading(true);
    setResults([]);

    try {
      const localResults = await fetchLocalResults(keyword, city, country);

      if (localResults.length > 0) {
        setResults(localResults);
        toast.success(`Found ${localResults.length} results for "${keyword}" in ${city}, ${country}`);
      } else {
        setError('No matching records found.');
        toast.info('No matching records found.');
      }
    } catch (err) {
      console.error('Error during search:', err.message);
      setError('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const getWebsiteProtocolStyle = (protocol) => {
    return protocol === 'HTTPS' ? 'green' : protocol === 'HTTP' ? 'red' : '';
  };


  const getOpeningStatusStyle = (status) => {
    if (!status) return '';
    const normalizedStatus = status.toLowerCase().trim();
    console.log('Opening Status:', status); 
    if (normalizedStatus === 'open') return 'green';
    if (normalizedStatus === 'closed') return 'red';
    return '';
  };


  const getBusinessStatusStyle = (status) => {
    if (!status) return '';
    const normalizedStatus = status.toLowerCase().trim();
    console.log('Business Status:', status); 
    if (normalizedStatus === 'operational') return 'green';
    if (normalizedStatus === 'temporarily_closed') return 'orange';
    if (normalizedStatus === 'closed') return 'red';
    return '';
  };

  return (
    <div className="ranking-container">
      <h2>Local Search Rank Analysis</h2>
      <div className="input-container">
        <form onSubmit={handleSearch} className="ranking-form">
          <div className="form-group">
            <input
              id="keyword"
              type="text"
              placeholder="Enter Keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <input
              id="city"
              type="text"
              placeholder="Enter City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <input
              id="country"
              type="text"
              placeholder="Enter Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
              className="form-input"
            />
          </div>
          
          {error && <p className="error-message" role="alert">{error}</p>}
          <button 
            type="submit" 
            className="search-button" 
            disabled={loading}
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </form>
      </div>

      {results.length > 0 && (
        <div className="results-container">
          <h3>Top Local Results:</h3>
          <table>
            <thead>
              <tr>
                <th>Business Name</th>
                <th>Phone Number</th>
                <th>Website</th>
                <th>Primary Category</th>
                <th>Rating</th>
                <th>Total Reviews</th>
                <th>Verified</th>
                <th>Business Status</th>
                <th>Current Opening Status</th>
                <th>Secondary Category</th>
                <th>Website Protocol</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Zip Code</th>
                <th>Distance</th>
                <th>Last Review Date</th>
                <th>Last Review Reply</th>
                <th>Photo Count</th>
                <th>Email</th>
                <th>Review URL</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={index}>
                  <td>{result.business_name}</td>
                  <td>{result.phone_number || 'Not available'}</td>
                  <td>
                    <a href={result.website_url} target="_blank" rel="noopener noreferrer">
                      {result.website_url || 'Not available'}
                    </a>
                  </td>
                  <td>{result.primary_category || 'Not available'}</td>
                  <td>{result.rating || 'Not available'}</td>
                  <td>{result.total_reviews || 'Not available'}</td>
                  <td>{result.verified || 'Not available'}</td>
                  <td className={getBusinessStatusStyle(result.business_status)}>
                    {result.business_status || 'Unknown'}
                  </td>
                  <td className={getOpeningStatusStyle(result.is_open_now)}>
                    {result.is_open_now || 'Not available'}
                  </td>
                  <td>{result.secondary_category || 'Not available'}</td>
                  <td className={getWebsiteProtocolStyle(result.website_protocol)}>
                    {result.website_protocol || 'Not available'}
                  </td>
                  <td>{result.city || 'Not available'}</td>
                  <td>{result.state || 'Not available'}</td>
                  <td>{result.country || 'Not available'}</td>
                  <td>{result.zip_code || 'Not available'}</td>
                  <td>{result.distance_from_searched_location || 'Not available'}</td>
                  <td>{result.last_review_date || 'Not available'}</td>
                  <td>{result.last_review_reply || 'Not available'}</td>
                  <td>{result.photo_count || 'Not available'}</td>
                  <td>{result.email || 'Not available'}</td>
                  <td>
                    <a href={result.review_url} target="_blank" rel="noopener noreferrer">
                      Write A Review
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Localranking;
